@import '../../../styles/customMediaQueries.css';
.mapListingsWrapper {
  display: flex;
}
.listingResultSec {
  display: flex;
  flex-direction: column;
  max-width: 350px;
  gap: 8px;
  width: 100%;
  height: 100%;
  max-height: 90vh;
  overflow-y: auto;
  padding: 8px;
  @media (--viewportLarge) {
    max-width: 450px;
  }
  & > a {
    padding: 8px;
    @media (--viewportLarge) {
      padding: 16px;
    }
  }
}
.modalfilterSec {
  padding: 8px;
  width: 100%;
  max-width: 230px;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  @media (--viewportLarge) {
    max-width: 280px;
  }
  & > div {
    margin: 0;
  }
}

@import '../../../styles/customMediaQueries.css';

.root {
  width: 100%;
  height: 100%;
  background-color: var(--colorGrey100);
}

.mapRoot {
  width: 100%;
  height: 100%;
}

.reusableMap {
  position: relative;
  @media (--viewportMedium) {
    width: calc(100% - 350px - 230px);
    height: calc(100vh - 100px);
  }
  @media (--viewportLarge) {
    width: calc(100% - 450px - 280px);
  }
}

.defaultMapLayout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  @media (--viewportMedium) {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    flex-grow: 1;
  }
}

/**
 * When reusable map is attached right to the body it's hidden.
 * Specificity rule is added to overwrite positioning coming from props.className
 */
body > .reusableMapHidden {
  position: absolute;
  top: -1000px;
  left: -1000px;
  visibility: hidden;
  opacity: 0;

  @media (--viewportMedium) {
    top: -1000px;
    left: -1000px;
    right: auto;
  }
}
